import { useEffect, useRef, useState } from "react";
import { storage } from "../firebase";

import logo from "./logoNew.png";

import perlin from "perlin-noise";
import { extractColors } from "extract-colors";
import chroma from 'chroma-js';
import { Buffer } from "buffer";
window.Buffer = window.Buffer || Buffer;

export default class ElgioDesign {
    element({ data, location,  city }) {
        return <div className="text-white text-4xl" style={{ height: 1920, width: 1080 }}>
            <div className="w-full h-full flex flex-col">
                <div className="h-1/3">
                    <ReferenceImage reference={(data?.images?.length??0) > 0 ? data?.images[0] : null} />
                </div>
                <div className="relative h-2/3">
                    <Background data={data} />
                    <div className="absolute h-full w-full flex-grow py-12 px-10 flex flex-col">
                        <div className="flex flex-col">
                            <div className="text-7xl my-2 font-bold">
                                {data?.name??'-'}
                            </div>
                            <div className="text-4xl mt-4 mb-2 font-semibold text-gray-300">
                                <span>{data?.start?.toDate()?.toLocaleString('de-DE', {weekday: "long"})}</span>
                                <span>, {data?.start?.toDate()?.toLocaleString('de-DE', {day: "2-digit", month: "long"})}</span>
                                <span> {data?.start?.toDate()?.toLocaleString('de-DE', {hour: "2-digit", minute: "2-digit"})}</span>
                            </div>
                            {data?.price && <div className="text-4xl font-semibold text-gray-300">{data.price}</div>}
                        </div>
                        <div className="flex justify-between mt-28">
                            {data?.lineup?.length > 0 && 
                                <div className="flex flex-col">
                                    <div className="mb-3 text-gray-300">Lineup</div>
                                    <div className="font-semibold flex flex-col">
                                    {data?.lineup?.map((item, index) => (
                                        <div key={index} className="mb-1" style={{fontSize: "40px"}}>
                                        {item}
                                        </div>
                                    ))}
                                    </div>
                                </div>
                            }
                            {data?.genres?.length > 0 &&
                                <div className="flex flex-col items-end ml-auto">
                                    <div className="mb-3 text-gray-300">Genres</div>
                                    <div className="font-semibold flex items-end flex-col">
                                    {data?.genres?.map((item, index) => (
                                        <div key={index} className="mb-1" style={{fontSize: "40px"}}>
                                            {item}
                                        </div>
                                    ))}
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="mt-auto w-full flex justify-between items-end p-3">
                            <div>
                                <div className="font-semibold text-5xl mb-2">{location}</div>
                                <div className="font-semibold text-gray-300">{city}</div>
                            </div>
                            <div>
                                <img className="h-48" style={{marginBottom: "-3rem"}} src={logo} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}

function ReferenceImage({className, reference, ...rest}) {
    const [url, setUrl] = useState('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==');

    useEffect(() => reference ? storage.ref(reference).getDownloadURL().then(url => setUrl(url)).catch(_ => setUrl('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==')) : null, [reference]);

    return <div className={className??'w-full h-full'} style={{
        backgroundImage: `url(${url})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    }}/>;
}

function Background({ data }) {
    const ref = useRef();

    useEffect(() => (data?.images?.length??0) > 0 && data.images[0] && storage.ref(data.images[0]).getDownloadURL()
        // .then(url => axios.get(url, { responseType: 'arraybuffer' }))
        // .then(response => Buffer.from(response.data, 'utf-8'))
        .then(url => extractColors(url, { crossOrigin: 'anonymous' }))
        // .then(buffer => getColors(buffer, 'image/png'))
        .then(colors => {
            if(!ref.current) return;
            const canvas = ref.current;
            const width = canvas.width;
            const height = canvas.height;
            const context = canvas.getContext('2d');
            const noise = perlin.generatePerlinNoise(width, height, {
                octaveCount: 7,
                amplitude: 1,
                persistance: 0.05,
            });
            colors = colors.map(e => chroma.hex(e.hex));
            colors.sort((a, b) => b.hsv()[2] < 0.5 ? -1 : b.hsv()[1] - a.hsv()[1]);
            colors = colors.map(e => e.hsv()[2] < 0.5 ? e.brighten(1) : e );
            for(var i = 0; i < height; i++) {
                for(var j = 0; j < width; j++) {
                    context.fillStyle = colors[0].mix(colors[1], noise[width * i + j]).saturate(1).darken(1).hex();
                    context.fillRect(j, i, 1, 1);
                }
            }
        }), [data?.images]);

    return <canvas ref={ref} className="absolute h-full w-full" />;
}